<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 线下商品维护 </template>
      <template #input>
        <a-input v-model="name" placeholder="请输入商品名称" />
        <a-button type="primary" class="btn" @click="search()">搜索</a-button>
        <a-button class="all_boder_btn" @click="visible = true"
          >新建商品</a-button
        >
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item) => item.productId"
        :columns="columns"
        :data-source="tableData"
        :loading="loading"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNum,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNum - 1) * 10 + i + 1 }}
          </div>
        </template>

        <template slot="type" slot-scope="item">
          {{typeText(item)}}
        </template>
        
        <template slot="operation" slot-scope="text">
          <div class="btn_router_link">
            <router-link to @click.native="selEdit(text)">编辑</router-link>
            <span>|</span>
            <router-link to @click.native="del(text)">删除</router-link>
            <span>|</span>
            <router-link @click.native="openQRCodeModel(text)" to>生成二维码</router-link>
          </div>
        </template>
      </a-table>
    </div>

    <!-- 新建/编辑 -->
    <a-modal
      v-model="visible"
      :title="modelForm.productId?'编辑':'新建'"
      @ok="setData()"
      :confirm-loading="modelLoading"
      @cancel="handleCancel"
      okText="确认"
    >
      <a-row class="rows">
        <a-col span="5" class="left">商品名称</a-col>
        <a-col span="19" class="right">
          <a-input placeholder="请输入商品名称" v-model="modelForm.name" />
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="5" class="left">商品类型</a-col>
        <a-col span="19" class="right">
          <a-select placeholder="请选择商品类型" v-model="modelForm.type">
            <a-select-option :value="item.taxRateConfigId" v-for="item in typeList" :key="item.taxRateConfigId">
              {{item.productTypeTxt}}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="5" class="left">定价</a-col>
        <a-col span="19" class="right">
          <a-input-number id="inputNumber" placeholder="请填写定价" v-model="modelForm.couponPrice" :min="0.01" />
        </a-col>
      </a-row>
    </a-modal>

    <!-- 二维码弹框 -->
    <a-modal
      v-model="QRCodevisible"
      title="生成二维码"
      @ok="copyQRCode()"
      :confirm-loading="QRCodemodelLoading"
      @cancel="QRCodehandleCancel"
      okText="确认"
    >
      <a-row class="rows">
        <a-col span="5" class="left">付款金额</a-col>
        <a-col span="19" class="right">
          <a-input-number id="inputNumber" placeholder="请填写付款金额" v-model="QRCodemodelForm.price" :min="0.01" />
        </a-col>
      </a-row>
    </a-modal>


    <!-- 二维码图片弹框 -->
    <a-modal
      width="400px"
      v-model="QRCodeImgvisible"
      title="收款码预览"
      class="review"
    >
      <template #footer>
        <a-button class="all_boder_btn" @click="copyImage(imageSrc)"
          >复制</a-button
        >
        <a-button class="all_boder_btn" @click="downloadBase64Image(imageSrc,'收款码')"
          >下载</a-button
        >
      </template>
      <img style="width: 100%;" :src="imageSrc" alt="">
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "商品类型",
    align: "center",
    dataIndex: "type",
    scopedSlots: { customRender: "type" },
  },
  {
    title: "商品名称",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "定价",
    align: "center",
    dataIndex: "couponPrice",
  },
  {
    title: "操作",
    align: "center",
    width: "200px",
    scopedSlots: { customRender: "operation" },
  }
];
import HeaderBox from "@/components/HeaderBox.vue";
import copyQrCodeVue from './copyQrCode.vue';

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  mixins: [copyQrCodeVue],
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNum: 1, //页码
      pageSize: 10, // 页数
      // 关键字
      name: "",
      // 商品类型列表
      typeList: [],
      // 分类id
      code: "",

      // 新建弹框
      visible: false,
      modelLoading: false,
      modelForm: {
        couponPrice: '',
        type: undefined,
        name: ''
      },

      // 二维码弹框
      QRCodevisible: false,
      QRCodemodelLoading: false,
      QRCodemodelForm: {
        price: ''
      },

      imageSrc: '',
      QRCodeImgvisible: false,

    };
  },
  // 事件处理器
  methods: {
    // 复制二维码弹框
    openQRCodeModel(e) {
      this.QRCodemodelForm.productId = e.productId;
      this.QRCodemodelForm.productName = e.name;
      this.QRCodevisible = true;
    },
    // 弹框取消
    QRCodehandleCancel() {
      this.QRCodemodelForm = {
        price: ''
      }
      this.QRCodevisible = false;
      this.QRCodemodelLoading = false;
    },
    

    // 弹框取消
    handleCancel() {
      this.visible = false;
      this.modelForm = {
        couponPrice: '',
        type: undefined,
        name: ''
      }
    },
    // 新建/编辑商品
    setData() {
      // 校验必填
      if(!this.modelForm.name) {
        return this.$message.warning('请输入商品名称！')
      } else if(!this.modelForm.type) {
        return this.$message.warning('请选择商品类型！')
      } else if(!this.modelForm.couponPrice) {
        return this.$message.warning('请输入商品定价！')
      }
      this.modelLoading = true;
      // 默认新建
      let url = '/hxclass-management/product-archives/addOfflineProducts',
          method = 'post';
      if(this.modelForm.productId) {
        // 编辑时
        url = '/hxclass-management/product-archives/updateOfflineProducts';
        method = 'put';
      }
      this.$ajax({
        url,
        method,
        params: this.modelForm
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.handleCancel();
          this.$message.success("操作成功");
          this.getdataList();
        } else {
          this.$message.error(res.message);
        }
        this.modelLoading = false;
      })
    },
    // 分页
    onPage(e) {
      this.pageNum = e.current;
      this.pageSize = e.pageSize;
      this.getdataList();
    },
    // 编辑
    selEdit(e) {
      this.visible = true;
      this.modelForm = e;
    },
    // 搜索
    search() {
      this.pageNum = 1;
      this.getdataList();
    },
    // 获取数据
    getdataList() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/product-archives/selectOfflineProductsList",
        method: "get",
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.name,
        },
      }).then((res) => {
        this.loading = false
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },

    // 删除商品
    del(e) {
      this.$confirm({
        title: '确定删除该商品吗?',
        centered: true,
        okText: '确认',
        onOk:()=> {
          this.$ajax({
            url:"/hxclass-management/product-archives/deleteOfflineProducts?productId=" + e.productId,
            method: "put"
          }).then((res) => {
            if (res.code == 200 && res.success) {
              this.$message.success("操作成功");
              this.getdataList();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },

    // 查询商品类型列表
    getTypeList() {
      this.$ajax({
        url: '/hxclass-management/tax-rate/config/'
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.typeList = res.data.filter((item) => {
            return item.taxRateConfigId == 11 || item.taxRateConfigId == 12;
          });
        }
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 获取商品类型列表
    this.getTypeList();
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.getdataList();
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {
    typeText() {
      return function(e) {
        let str = '';
        this.typeList.some(item=>{
          if(item.taxRateConfigId == e) {
            str = item.productTypeTxt;
            return true;
          }
        })
        return str
      }
    }
  },
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
  /deep/ .ant-tabs-bar {
    margin: 0;
  }
}
.rows {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  .left {
    text-align: right;
    height: 32px;
    line-height: 32px;
    &::before{
      content: '*';
      color: red;
      margin-right: 2px;
    }
    &::after {
      content: "：";
    }
  }
  .unMust{
    &::before{
      content: '';
    }
  }
  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    .ant-input, .ant-select, .ant-input-number{
      flex: 1;
    }
  }
}
.review{
  /deep/.ant-modal-close{
    display: block;
  }
}
</style>
